<template>
  <div class="flex flex-col p-2 rounded shadow bg-white h-64 w-64">
    <img class="w-full h-2/3 object-cover" v-if="imageitem" :src="baseUrl + '/media/get/' + imageitem.ref" alt="">
    <div class="text-sm font-bold h-1/6 bg-slate-100 flex">
      <span class="text-wrap">{{ imageitem.name }}</span>
    </div>
    <div class="h-1/6 bg-zinc-300">
      <span class="text-xs text-white bg-red-400 flex justify-center items-center font-bold rounded p-2">Delete</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MediaList',
  props: ['imageitem'],
  data: function () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  methods: {
  }
}
</script>

<style>
.package-row {
  display: flex;
  flex-direction: row;
  background: #ECEFF1;
  width: 100%;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  gap: 10px;
  box-shadow: 1px 2px 1px -2px rgba(0,0,0,0.48);
  -webkit-box-shadow: 1px 2px 1px -2px rgba(0,0,0,0.48);
  -moz-box-shadow: 1px 1px 2px -2px rgba(0,0,0,0.48);
  margin-bottom: 10px;
}

.packagename {
  font-weight: 600;
  color: #2196F3;
}

.packagedesc {
  color: #000;
  font-size: 0.9rem;
}

</style>
