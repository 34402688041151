<template>
  <div class="container mx-auto">
    <div class="overflow-y-auto h-screen bg-slate-100">
      <div class="flex flex-col items-center max-w-96 gap-16">
        <div class="font-bold text-2xl py-4">
        About Us
        </div>
        <img src="/images/about_title.jpg" alt="">
    <div class="flex flex-col justify-center items-center gap-16 h-full  whitespace-pre-line text-center pb-8 px-4 md:pb-10">
      <div class="shadow rounded w-1/2 p-6 flex flex-col bg-white justify-center items-center animate__animated animate__backInUp">
        <span class="text-lg font-bold">Who we are</span>
        <div class="flex">
          <span class="w-full text-sm">The founders/Directors of this organization are indigenous youth Batswana whom have the passion and
vision of unlocking the destinations potential through creating creative trails which supports local
communities and using creative tourism as a tool for economic growth and diversity.</span>
        </div>
        <sundec></sundec>
      </div>

      <div class="shadow rounded w-1/2 p-6 flex flex-col bg-white justify-center items-center animate__animated animate__backInUp">
        <span class="text-lg font-bold">Work with us</span>
        <div class="flex">
          <span class="w-full text-sm">Just send us an email detailing the destinations, activities and dates and we will get back to you with a
range of options to suit your budget. Sit back, relax and we’ll take care of all the details so you can
simply enjoy your holiday.</span>
        </div>
        <sundec></sundec>
      </div>

      <div class="shadow rounded w-1/2 p-6 flex flex-col bg-white justify-center items-center animate__animated animate__backInUp">
        <span class="text-lg font-bold">What we do</span>
        <div class="flex">
          <span class="w-full text-sm">We arrange travel, tour packages and accommodation for corporate, groups and individuals in and
outside Botswana.</span>
        </div>
        <sundec></sundec>
      </div>

      <div class="shadow rounded w-1/2 p-6 flex flex-col bg-white justify-center items-center animate__animated animate__backInUp">
        <span class="text-lg font-bold">How we help</span>
        <div class="flex">
          <span class="w-full text-sm">We help tourists to know and have an experience with all the great destinations that they can travel to
in Botswana and outside, creating unforgettable lifetime memories in Africa.</span>
        </div>
        <sundec></sundec>
      </div>

      <div class="shadow rounded w-1/2 p-6 flex flex-col bg-white justify-center items-center animate__animated animate__backInUp">
        <span class="text-lg font-bold">The Experience</span>
        <div class="flex">
          <span class="w-full text-sm">“Experience” and feel of the destination is what, we at Afrigetaway, aim at achieving when creating
travel plans for our customer. Our team has many years of experience and strategic partnerships to
ensure your excursion runs smoothly.</span>
        </div>
        <sundec></sundec>
      </div>

      <div class="shadow rounded w-1/2 p-6 flex flex-col bg-white justify-center items-center animate__animated animate__backInUp">
        <span class="text-lg font-bold">Travel with us</span>
        <div class="flex">
          <span class="w-full text-sm">When it comes to travel, we want our customers and to know that through us you are not only leaving
footprints in your destination but are contributing to the realization of the development of creative
tourism which shall economically benefit the local community of the destination. Travel with us and be
part of a community in support of a noble cause.</span>
        </div>
        <sundec></sundec>
      </div>

      <div class="shadow rounded w-1/2 p-6 flex flex-col bg-white justify-center items-center animate__animated animate__backInUp">
        <span class="text-lg font-bold">Talk to us</span>
        <div class="flex">
          <span class="w-full text-sm"> Just send us an email detailing the destinations, activities and dates and we will get back to you with a
range of options to suit your budget. Sit back, relax and we’ll take care of all the details so you can
simply enjoy your holiday.</span>
        </div>
        <sundec></sundec>
      </div>

      <div class="shadow rounded w-1/2 p-6 flex flex-col bg-white justify-center items-center animate__animated animate__backInUp">
        <span class="text-lg font-bold">We are located at</span>
        <div class="flex flex-col gap-2">
          <div class="whitespace-pre-line font-semibold text-sm">Molepolole,
          Plot 17989,
          Mogogoru Ward,
          Next to Mafenyatla Mall, Molepolole, Botswana
        </div>

        <div class="flex gap-2">
          <span class="font-bold">Email:</span>
          <span>info@afrigetaway.com</span>
          <span>or</span>
          <span> info.afrigetaway@gmail.com</span>
        </div>
        <p>
          <span class="font-bold">Bookings:</span> bkeimetswe@afrigetaway.com or afrigetaway.b@gmail.com
        </p>
        <p>
          <span class="font-bold">Contact Number:</span> +267 73822252
        </p>
        <a class="font-semibold" href="https://www.facebook.com/afrigetaway">
            <svg fill="#203ecf" width="40" height="40" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><path d="M59.5 1H4.5C2.5 1 1 2.6 1 4.5V59.5C1 61.5 2.6 63 4.5 63H34.1V38.9H26.1V29.6H34.1V22.7C34.1 14.7 38.9 10.3 46.1 10.3C48.5 10.3 50.9 10.4 53.3 10.7V19H48.5C44.7 19 43.9 20.8 43.9 23.5V29.4H53L51.7 38.8H43.7V62.6H59.5C61.5 62.6 63 61.1 63 59.1V4.5C62.9 2.5 61.3 1 59.5 1Z"/></svg>
            Click here to chat with us.
          </a>
        </div>
        <sundec></sundec>
      </div>

      <div class="flex bg-white gap-2 rounded shadow flex-wrap p-4">
        <div class="flex flex-row justify-center mb-2 pb-2 md:pb-10 mt-2">
          <img class="w-52 h-full" src="/images/logo.png" alt="Afrigetaway">
        </div>
        <a href="https://www.hatab.bw/" class="flex flex-row justify-center mb-2 pb-2 md:pb-12">
          <img class="w-40 md:w-52 h-full" src="/images/hatab.png" alt="Hatab">
        </a>
        <div class="flex flex-row justify-center mb-2 pb-2 md:pb-10 mt-2">
          <img class="w-40 md:w-52 h-full" src="/images/tourism.png" alt="Tourism">
        </div>
        <a href="https://www.safarigo.com/" class="flex flex-row justify-center mb-2 pb-2 md:pb-10 mt-2 bg-gray-400 rounded shadow p-4">
          <img class="w-52 h-auto" src="/images/safarigo.png" alt="Tourism">
        </a>
        <a href="https://www.safaribookings.com/" class="flex flex-row justify-center mb-2 pb-2 md:pb-10 mt-2 bg-gray-400 rounded shadow p-4">
          <img class="md:w-52 h-full" src="/images/safaribooking.png" alt="Tourism">
        </a>
        <a href="https://mashatu.com/" class="flex flex-row justify-center mb-2 pb-2 md:pb-10 mt-2 bg-gray-400 rounded shadow p-4">
          <img class="md:w-52 h-full" src="/images/mashatu.png" alt="Tourism">
        </a>
        <a href="https://www.wildernessdestinations.com/" class="flex flex-row justify-center mb-2 pb-2 md:pb-10 mt-2 bg-gray-400 rounded shadow p-4">
          <img class="md:w-52 h-full" src="/images/wilderness.svg" alt="Tourism">
        </a>
        <a href="https://www.royalcaribbean.com/" class="flex flex-row justify-center mb-2 pb-2 md:pb-10 mt-2 bg-gray-400 rounded shadow p-4">
          <img class="md:w-52 h-full" src="/images/royal-caribbean.svg" alt="Tourism">
        </a>
      </div>
    </div>
    </div>
    </div>
  </div>
</template>
<script>
import sundec from '../components/decorations/sun_dec.vue'
export default {
  name: 'AboutUs',
  components: {
    sundec
  },
  data: function () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      loggedIn: this.$store.state.loggedIn
    }
  },
  methods: {
  },
  mounted () {
  },
  created () {
  }
}
</script>
<style scoped>
  .section h2 {
    color: black;
    font-size: 2em;
  }
  svg{
    display: inline-flex;
  }
</style>
