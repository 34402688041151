<template>
  <div id="booking-complete">

    <div class="booking-complete-container">
      <ErrorComponent :error="otp.error" v-if="showError" @closeError="closeErrorMessage()"/>
      <div class="bg-white p-4 rounded shadow flex flex-col gap-4" >

        <h3 class="text-lg font-bold text-cyan-500">Book Your Trip</h3>

        <div class="flex flex-col gap-4 justify-center content-center" v-if="showEmail">
          <p class="text-xs">Enter your email below and click continue</p>
          <input type="text" name="email" id="email" placeholder="Email" class="booking-input p-2" v-model="email">
          <div class="bookingform-continue-btn p-2 text-center" v-on:click="checkEmail">Continue</div>
        </div>
        <div class="flex flex-col gap-4 justify-center content-center" v-if="showName">
          <p class="text-xs">Enter your Name below and click continue</p>
          <input type="text" name="name" id="name" placeholder="Your Name" class="booking-input p-2" v-model="name">
          <div class="bookingform-continue-btn p-2" v-on:click="getName">Continue</div>
        </div>
        <div class="flex flex-col gap-4 justify-center content-center" v-if="showNumPeople">
          <p class="text-xs">How many people are coming?</p>
          <div class="flex gap-4 justify-center content-center">
            <span class="p-2 text-xl rounded shadow background bg-cyan-500 text-white" @click="counterdown()">-</span>
            <span class="p-2 text-xl font-bold">{{quantity}}</span>
            <span class="p-2 text-xl rounded shadow background bg-cyan-500 text-white" @click="counterup()">+</span>
          </div>

          <div class="hidden">
            <p>Adults</p>
            <div class="flex gap-4 justify-center content-center">
            <span class="p-2 text-xl rounded shadow background bg-cyan-500 text-white" @click="counterdown()">-</span>
            <span class="p-2 text-xl font-bold">{{adult_quantity}}</span>
            <span class="p-2 text-xl rounded shadow background bg-cyan-500 text-white" @click="counterup()">+</span>
          </div>
          </div>

          <div class="hidden">
            <p>Children(12 years and below)</p>
            <div class="flex gap-4 justify-center content-center">
            <span class="p-2 text-xl rounded shadow background bg-cyan-500 text-white" @click="counterdown()">-</span>
            <span class="p-2 text-xl font-bold">{{adult_quantity}}</span>
            <span class="p-2 text-xl rounded shadow background bg-cyan-500 text-white" @click="counterup()">+</span>
          </div>
          </div>
          <div class="bookingform-continue-btn p-2" v-on:click="placeBooking()">Continue</div>
        </div>
        <div class="flex flex-col gap-4 justify-center content-center" v-if="showOTP">
          <p class="text-xs">Enter confirmation OTP sent to Email.</p>
          <input type="text" name="otp" id="otp" placeholder="OTP" class="booking-input p-2" v-model="otp.value">
          <div class="bookingform-continue-btn  p-2 text-center" v-on:click="validateOTP">Confirm</div>
        </div>
        <div class="bookingform-cancel-btn  p-2 text-center text-aforange-normal" @click.prevent="cancelBooking">
                       Cancel

        </div>
        <div class="bookingform-preloader" v-if="showPreloader">
          <svg xmlns="http://www.w3.org/2000/svg" width="37.393" height="37.634" viewBox="0 0 37.393 37.634" class="animate__animated animate__heartBeat animate__infinite">
            <g id="Group_1424" data-name="Group 1424" transform="translate(0 37.634)">
              <path id="Path_11794" data-name="Path 11794" d="M26.071-31.122A17.057,17.057,0,0,0,10.7-28.848c-4.257,3.1-5.307,8.283-4.431,13.228C7.786-7.049,17.151-4.4,24.468-7.669c3.617-1.619,5.718-5.5,6.073-9.348.39-4.206-3.353-7.873-7.12-9.145-4.5-1.52-8.464.656-10.068,4.986s.867,7.9,5.507,8.155A4.654,4.654,0,0,0,23.608-16.5c.509-2.348-2.229-3.863-3.993-4.536-1.434-.547-2.049,1.752-.631,2.293a5.959,5.959,0,0,1,1.414.707q1.087,2.186-.7,2.535a4.18,4.18,0,0,1-3.1-.519c-1.957-1-1.579-3.09-.845-4.756,1.6-3.624,5.258-4.347,8.584-2.38a11.3,11.3,0,0,1,3.358,3.328c1.075,1.648.3,3.706-.26,5.378-.988,2.962-3.154,4.6-6.118,5.4a12.568,12.568,0,0,1-8.083-.6c-3.1-1.344-4.225-4.082-4.785-7.24C6.7-26.728,17.277-31.475,25.44-28.829,26.9-28.356,27.524-30.651,26.071-31.122Z" fill="#f6b45e"/>
              <path id="Path_11795" data-name="Path 11795" d="M18.919-33.594H23.56l-2.321-4.04Z" fill="#f6b45e"/>
              <path id="Path_11796" data-name="Path 11796" d="M11.851-31.632l4.407-1.451-3.469-3.112Z" fill="#f6b45e"/>
              <path id="Path_11797" data-name="Path 11797" d="M6.566-27.485,9.909-30.7,5.437-32.006Z" fill="#f6b45e"/>
              <path id="Path_11798" data-name="Path 11798" d="M3.8-21.343l1.415-4.419-4.556.977Z" fill="#f6b45e"/>
              <path id="Path_11799" data-name="Path 11799" d="M4.279-14.67l-.528-4.61L0-16.516Z" fill="#f6b45e"/>
              <path id="Path_11800" data-name="Path 11800" d="M7.763-8.328,5.22-12.209,3.112-8.054Z" fill="#f6b45e"/>
              <path id="Path_11801" data-name="Path 11801" d="M13.2-4.269,9.033-6.314l.3,4.65Z" fill="#f6b45e"/>
              <path id="Path_11802" data-name="Path 11802" d="M20.953-4.151l-4.634.231L18.838,0Z" fill="#f6b45e"/>
              <path id="Path_11803" data-name="Path 11803" d="M28.011-7.043l-4.048,2.27,4,2.389Z" fill="#f6b45e"/>
              <path id="Path_11804" data-name="Path 11804" d="M32.444-12.386,29.862-8.531l4.648.322Z" fill="#f6b45e"/>
              <path id="Path_11805" data-name="Path 11805" d="M33.517-19.9l-.311,4.628,4.187-2.044Z" fill="#f6b45e"/>
              <path id="Path_11806" data-name="Path 11806" d="M29.34-26.551l2.677,3.791,1.963-4.225Z" fill="#f6b45e"/>
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import axios from 'axios'
import router from '@/router'
import ErrorComponent from '../components/public/ErrorComponent.vue'
export default {
  name: 'BookingComplete',
  components: {
    ErrorComponent
  },
  data: function () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      token: this.$store.state.token,
      page_ready: false,
      formstate: {
        name_error: false,
        email_error: false,
        quantity_error: false,
        phone_error: false,
        has_error: false
      },
      show_btn: true,
      show_message: false,
      show_form: true,
      name: '',
      email: '',
      phone: '',
      quantity: 1,
      tpackage: [],
      showpackage: false,
      showbookingForm: true,
      showName: false,
      showEmail: false,
      showPhone: false,
      showNumPeople: false,
      showPreloader: false,
      showOTP: false,
      showError: false,
      otp: {
        value: '',
        sent: false,
        recieved: false,
        inprogress: false,
        haserror: false,
        error: {
          message: '',
          code: ''
        }
      }
    }
  },
  methods: {
    getpackage: function () {
      axios(
        {
          method: 'post',
          url: process.env.VUE_APP_BASE_URL + '/api/get/package/' + this.$route.params.packageref
        })
        .then((response) => {
          console.log(response.data.package)
          this.tpackage = response.data.package
          this.page_ready = true
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    placeBooking: function () {
      this.togglePreloader()
      this.toggleNumOfPeople()
      console.log('token : ' + this.tpackage.ref)
      const myFormData = new FormData()
      myFormData.set('package', this.tpackage.ref)

      this.formstate.name_error = false
      this.formstate.email_error = false
      this.formstate.phone_error = false
      this.formstate.quantity_error = false
      this.formstate.has_error = false

      if (this.name !== '') {
        myFormData.set('name', this.name)
      } else {
        this.formstate.name_error = true
        this.formstate.has_error = true
      }

      if (this.email !== '') {
        myFormData.set('email', this.email)
      } else {
        this.formstate.email_error = true
        this.formstate.has_error = true
      }

      // if (this.phone !== '') {
      //   myFormData.set('phone', this.phone)
      // } else {
      //   this.formstate.phone_error = true
      //   this.formstate.has_error = true
      // }

      if (this.quantity !== '') {
        myFormData.set('quantity', this.quantity)
      } else {
        this.formstate.quantity_error = true
        this.formstate.has_error = true
      }

      if (!this.formstate.has_error) {
        console.log('Posting....')
        console.log(JSON.stringify(myFormData))
        axios({
          method: 'post',
          data: myFormData,
          url: process.env.VUE_APP_BASE_URL + '/api/booking/create',
          headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + this.token, 'Access-Control-Allow-Origin': '*' }
        })
          .then((response) => {
            console.log(response)
            this.togglePreloader()
            this.requestOTP()
          })
          .catch(function (error) {
            this.togglePreloader()

            console.log(error)
          })
      }
    },
    toggleEmail: function () {
      this.showEmail = !this.showEmail
    },
    toggleName: function () {
      this.showName = !this.showName
    },
    togglePreloader: function () {
      this.showPreloader = !this.showPreloader
    },
    toggleNumOfPeople: function () {
      this.showNumPeople = !this.showNumPeople
    },
    toggleOTP: function () {
      this.showOTP = !this.showOTP
    },
    checkEmail: function () {
      this.toggleEmail()
      this.togglePreloader()
      const myFormData = new FormData()
      myFormData.set('email', this.email)
      axios(
        {
          method: 'post',
          url: process.env.VUE_APP_BASE_URL + '/api/email/exists',
          data: myFormData
        })
        .then((response) => {
          if (response.data.exist) {
            this.toggleName()
            this.togglePreloader()
          } else {
            this.toggleName()
            this.togglePreloader()
          }
        })
        .catch((error) => {
          console.log(error)
          this.toggleEmail()
          this.togglePreloader()
        })
    },
    getName: function () {
      this.toggleName()
      this.toggleNumOfPeople()
    },
    requestOTP: function () {
      this.togglePreloader()
      const myFormData = new FormData()
      console.log('authenticating' + this.email + 'requesting OTP')
      myFormData.set('email', this.email)
      axios({
        method: 'post',
        url: process.env.VUE_APP_BASE_URL + '/api/email/login/resend',
        data: myFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then((response) => {
          this.otp.sent = true
          if (response.data.success === false) {
            this.togglePreloader()
            this.showError = true
            this.otp.recieved = false
            this.otp.error.message = response.data.message
            // this.otp.error.code = response.data.error_code
          } else {
            this.otp.recieved = true
            this.toggleOTP()
            this.togglePreloader()
          }
        })
        .catch(function (error) {
          this.togglePreloader()

          console.log(error)
        })
    },
    validateOTP: function () {
      this.toggleOTP()
      this.togglePreloader()

      const myFormData = new FormData()
      console.log('authenticating' + this.email + 'with pass ' + this.password)
      myFormData.set('token', this.otp.value)
      myFormData.set('email', this.email)
      axios({
        method: 'post',
        url: process.env.VUE_APP_BASE_URL + '/api/email/login/confirm',
        data: myFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then((response) => {
          // console.log(response)
          this.togglePreloader()

          if (response.data.success === true) {
            this.$store.dispatch('setTokenAction', response.data._token)
            this.$store.commit('setLoggedInUser', response.data.user)
            this.user = response.data.user
            // console.log(this.user.role.alias)
            if (this.user.isAdmin) {
              router.push('/admin/dashboard')
            } else {
              router.push('/member/dashboard')
            }
          } else {
            this.toggleOTP()
            this.showError = true
            this.otp.error.message = response.data.message
          }
        })
        .catch(function (error) {
          this.toggleOTP()
          this.togglePreloader()

          console.log(error)
        })
    },
    counterup: function () {
      if (this.quantity < 10) {
        this.quantity = this.quantity + 1
      }
    },
    counterdown: function () {
      if (this.quantity > 1) {
        this.quantity = this.quantity - 1
      }
    },
    closeErrorMessage: function () {
      this.otp.error.message = ''
      this.showError = false
    },
    cancelBooking: function () {
      router.push({ path: '/booking/' + this.$store.state.selectedTravelPackage.ref })
    }
  },
  created () {
    this.toggleEmail()
    this.getpackage()
  }

}
</script>
<style scoped>
  .booking-complete-container {
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('../assets/images/bg.png');
    background-size: cover;
  }

  .bookingform-container {
    padding: 10px;
    border-radius: 5px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  .booking-input {
    border-radius: 5px;
    border: 1px #0097A7 solid;
  }

  .bookingform-header {
    color: #00897B;
    font-weight: 700;
  }

  .bookingform-continue-btn {
    margin-top: 5px;
    padding: 5px;
    background-color: #0277BD;
    color: #fff;
    font-weight: 800;
    border-radius: 5px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    cursor: pointer;
     transition-duration: 0.5s;
  }

  .bookingform-cancel-btn {
    margin-top: 5px;
    padding: 5px;
    color: #fff;
    background-color: #f16061;
    font-weight: 800;
    border-radius: 5px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    cursor: pointer;
     transition-duration: 0.5s;
  }

  .bookingform-continue-btn:hover {
    transform: scale(1.05);
    transition-duration: 0.5s;
  }

  .bookingform-content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .bookingform-preloader {
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bookingform-preloader svg {
    transform: scale(0.7);
  }

  .package-view {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .package-img-container {
    height: 30vh;
    width: 100%;
  }

  .package-header {
    z-index: 10;
  }
  .package-img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(1px) brightness(0.54);
  }

  .package-header h1{
    color: #000;
    font-weight: 800;
  }

  .error-text {
    color: #e53935;
    font-weight: 800;
  }

  .alert-bookings {
    height: 20%;
    width: 50%;
    background-color: #FBC02D;
    color: #fff;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 1px 2px 5px -3px rgba(0,0,0,0.75);
    -webkit-box-shadow: 1px 2px 5px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 2px 5px -3px rgba(0,0,0,0.75);
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%,0%);
    font-size: 2rem;
    line-height: 1.2;
  }
  .package-details {
    margin: 3%;
    margin-top: 2vh;
    width: 100%;
  }

  .itenary {
    margin-top: 15px;
    display: flex;
    justify-content: flex-start;
  }

  .itenary .details {
    width:50%;
  }

  .itenary .gallery {
    width:50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow: scroll;
    height: 50vh;
  }

  .itenary .booking-form {
    width: 100%;
  }

  .itenary .gallery img{
    max-height: 150px;
    max-width: 200px;
  }

  .p-header-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content:start;
    align-items: center;
  }

  .booking-btn {
    width: 40%;
    color: white;
    background-color: #00ACC1;
    border-radius: 5px;
    padding: 5px;
  }

  .moreinfo-btn {
    width: 40%;
    color: #00ACC1;
    border: 2px solid #00ACC1;
    border-radius: 5px;
    padding: 5px;
    margin-left: 5px;
  }

  .btn-container {
    display: flex;
    width:100%;
    /* width: 600px; */
  }

  .pricing {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .title-disc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    text-align: left;
  }
</style>
