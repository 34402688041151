<template>
  <div class="container md:mx-auto">
    <BackButtonComponent to="/"></BackButtonComponent>
  <div class="flex flex-col h-screen  mb-20">

    <div class="bg-white rounded px-4 mx-4 md:mx-auto overflow-y-auto overflow-x-hidden max-h-96" v-if="hasBlogDetails">
      <div class="flex flex-col justify-center">
        <div class="blog-headerimg">
          <img v-if="selectedblog.headerimg" v-lazy="{src:mediaUrl + selectedblog.headerimg.ref,loading:'/images/loader.gif',error:'/images/loader.gif'}" alt="" class="bg-slate-200">
        </div>
        <div class="text-black text-2xl">
          {{selectedblog.title}}
        </div>
        <div class="">
          <div class="flex justify-center whitespace-pre-line mb-20 p-2">
            {{selectedblog.content}}
          </div>

        </div>
      </div>
      <div class="right-pane">
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import BackButtonComponent from '../components/backButtonComponent.vue'
export default {
  name: 'BlogView',
  components: {
    BackButtonComponent
  },
  data: function () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      mediaUrl: process.env.VUE_APP_BASE_URL + '/media/get/',
      blogs: []
    }
  },
  methods: {
    getBlog: function () {
      this.selectedBlog = this.$store.state.selectedblog
      console.log(JSON.stringify(this.selectedBlog))
    }
  },
  computed: {

    ...mapState(['selectedblog']),
    hasBlogDetails: function () {
      return this.selectedblog != undefined && this.selectedblog != null
    }
  },
  mounted () {
  },
  created () {
    // this.getBlog()
  }
}
</script>
<style scoped>
  .blog-container{
    width: 100%;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    background: #EFEBE9;
    min-height: 88vh;
  }

  .blog-headerimg{
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .blog-headerimg img{
    width: 100%;
    height: 30vh;
    border-radius: 10px;
    object-fit: cover;
  }

  .blog-title{
    color: black;
    font-size: 2em;
  }
  .backgroundsvg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .backgroundsvgdancers {
    position: fixed;
    z-index: -10;
    bottom: 0px;
    left: 0px;
  }
  .packages-container {
    width: 80%;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 20px;
  }
</style>
