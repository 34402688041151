<template>
  <div class="app header-default side-nav-dark">
    <div class="layout">
      <adminheader></adminheader>
      <Sidenav></Sidenav>
      <!-- Page Container START -->
      <div class="page-container">
          <!-- Content Wrapper START -->
          <div class="main-content">
            <div class="container-fluid">
              <breadcrumb title="New Package"></breadcrumb>
              <div class="col-12 col-xl-10 m-b-10 offset-xl-1 offset-m-b-1">
                <div class="card">
                  <div class="card-header border-bottom">
                    <h4 class="card-title">New Package</h4>
                  </div>
                  <div class="card-body">
                    <!-- start form1 -->
                    <div class="row" v-if="this.newPackageForm.step === 1">
                      <div class="col-12">
                        <div class="form-group">
                          <label>Package Details</label>
                          <div class="form-group col-md-6">
                            <label for="exampleInputName1">Name</label>
                            <input type="text" class="form-control" id="exampleInputName1" placeholder="Name" v-model="name">
                          </div>
                          <div class="form-group col-md-6">
                            <label for="exampleInputName1">Price</label>
                            <input type="money  " class="form-control" id="exampleInputName1" placeholder="Price" v-model="price">
                          </div>
                        </div>
                        <div class="form-group m-b-20 col-md-12">
                          <label for="exampleTextarea1">Description</label>
                          <textarea class="form-control" id="exampleTextarea1" rows="4" v-model="description"></textarea>
                        </div>
                      </div>
                    </div>
                    <!-- End form1 -->
                     <!-- start form2 -->
                    <div class="row" v-if="this.newPackageForm.step === 2">
                      <div class="col-12">
                        <div class="form-group">
                          <label>Package Header</label>
                          <div class="custom-file" v-if="showImageForm">
                            <input type="file" class="custom-file-input" id="inputGroupFile02">
                            <label class="custom-file-label" for="inputGroupFile02">Choose file</label>
                          </div>
                          <div class="gallery">
                            <div class="image-container" v-on:click="selectImage(item.ref)"  v-for="item in mediafiles" :key="item.ref">
                              <img :src="baseUrl + 'media/get/' + item.ref" alt="">
                            </div>
                            <div class="image-container" v-on:click="toggleShowImageForm">
                              <i class="lni lni-add-files" style="font-size: 60px"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- End form2 -->
                     <!-- start form3 -->
                    <div class="row" v-if="this.newPackageForm.step === 3">
                      <div class="col-12">
                        <div class="form-group">
                          <label>Create Itenary</label>
                        </div>
                      </div>
                    </div>
                    <!-- End form3 -->
                    <!--Btn next-->
                    <button type="button" v-on:click="prevPage" class="back-btn" v-if="this.newPackageForm.step > 1">Back</button>
                    <button type="button" v-on:click="createPackage" class="next-btn"  v-if="this.newPackageForm.step < 3">Save</button>
                    <button type="button" v-on:click="nextPage" class="next-btn"  v-if="this.newPackageForm.step === 3">Finish</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Content Wrapper END -->
        <adminfooter></adminfooter>
      </div>
      <!-- Page Container END -->
    </div>
  </div>
</template>
<script>
import adminheader from '../../components/admin/header.vue'
import Sidenav from '../../components/sidenav.vue'
import adminfooter from '../../components/adminfooter.vue'
import breadcrumb from '../../components/breadcrumb.vue'
import axios from 'axios'
import router from '../../router'

export default {
  name: 'NewPackage',
  components: {
    adminheader,
    Sidenav,
    adminfooter,
    breadcrumb
  },
  data: function () {
    return {
      packages: [],
      mediafiles: [],
      name: '',
      description: '',
      price: 0,
      baseUrl: process.env.VUE_APP_BASE_URL,
      newPackageForm: {
        showForm: true,
        step: 1
      },
      showImageForm: false,
      selectedImage: ''
    }
  },
  methods: {
    getpackages: function () {
      axios(
        {
          method: 'post',
          url: process.env.VUE_APP_BASE_URL + '/api/getpackages'
        })
        .then((response) => {
          console.log(response.data.data)
          if (typeof this.users !== 'undefined' || this.users !== null) {
            this.packages = response.data.data
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getmedia: function () {
      axios(
        {
          method: 'post',
          url: this.baseUrl + '/api/media'
        })
        .then((response) => {
          console.log(response)
          this.mediafiles = response.data.media
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    toggleShowImageForm: function () {
      this.showImageForm = !this.showImageForm
    },
    nextPage: function () {
      if (this.newPackageForm.step < 3) {
        this.newPackageForm.step += 1
      }
    },
    prevPage: function () {
      if (this.newPackageForm.step > 1) {
        this.newPackageForm.step -= 1
      }
    },
    createPackage: function () {
      const myFormData = new FormData()
      myFormData.set('name', this.name)
      myFormData.set('price', this.price)
      myFormData.set('description', this.description)

      axios({
        method: 'post',
        url: process.env.VUE_APP_BASE_URL + '/api/package/create',
        data: myFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then((response) => {
          console.log(response)
          router.push('/admin/packages')
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  created () {
    this.getmedia()
  }
}
</script>
<style scoped>
  .image-container {
    padding: 10px;
    width: 100px;
    height: 100px;
    margin-top: 10px;
    background-color: #fff;
    border-radius: 2px;
    -webkit-box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.32);
    box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.32);
  }

  .image-container .selected {
    background-color: #81D4FA;
  }

  .image-container img {
    width: 80px;
    height: 80px;
    object-fit: cover;
  }

  .image-container img {
    width: 80px;
    height: 80px;
    object-fit: cover;
  }

  .gallery {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 10px;
  }
  .card-body {
    text-align: left;
  }
  .back-btn {
    background-color: #FBC02D;
    font-weight: 800;
    color: #fff;
    border: none;
    border-radius: 5px;
    width: 100px;
    height: 30px;
  }

  .next-btn {
    background-color: #039BE5;
    font-weight: 800;
    color: #fff;
    border: none;
    border-radius: 5px;
    width: 100px;
    height: 30px;
    margin-left: 10px;
  }
</style>
