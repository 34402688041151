<template>
  <div class="min-h-screen min-w-full flex flex-row">
    <div class="flex w-full">
      <sidenav active="Dashboard"></sidenav>
      <div class="w-full">
        <div class="page-title">Dashboard</div>
        <div class="p-4 flex flex-wrap">
          <admin-stat v-for="item in stats" :stat="item" :key="item.id"></admin-stat>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidenav from '@/components/sidenav.vue'
import AdminStat from '@/components/admin/AdminStat.vue'

export default {
  name: 'AdminDashboard',
  components: {
    Sidenav,
    AdminStat
  },
  data: function () {
    return {
      stats: [
        {
          id: '1',
          title: 'Test Title',
          data: 2000
        },
        {
          id: '2',
          title: 'Test Title 2',
          data: 4000
        }
      ]
    }
  },
  comments: {
  }
}
</script>
<style scoped>
  .pagecontainer {
    display: flex;
    flex-direction: column;
  }

  .btn-open-package {
    background-color: #4FC3F7;
    color: #fff;
    font-weight: 700;
    padding: 10px 10px;
    border-radius: 5px;
  }
  .adminbody {
    display: grid;
    grid-template-columns: 250px 1fr;
  }

  .pagecontent {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }

  .stats-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .page-title {
    padding: 20px;
    font-size: 1.5rem;
    font-weight: 600;
  }
</style>
