<template>
  <article class="rounded-lg border border-gray-100 bg-white p-6">
    <div class="flex items-center justify-between">
      <div>
        <p class="text-sm text-gray-500">{{ stat.title }}</p>

        <p class="text-2xl font-medium text-gray-900">{{  }}</p>
      </div>

      <span class="rounded-full bg-blue-100 p-3 text-blue-600">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier"> 
            <path d="M16.719 19.7519L16.0785 14.6279C15.8908 13.1266 14.6146 12 13.1017 12H12H10.8983C9.38538 12 8.10917 13.1266 7.92151 14.6279L7.28101 19.7519C7.1318 20.9456 8.06257 22 9.26556 22H12H14.7344C15.9374 22 16.8682 20.9456 16.719 19.7519Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <circle cx="12" cy="5" r="3" stroke="#000000" stroke-width="2"></circle> 
            <circle cx="4" cy="9" r="2" stroke="#000000" stroke-width="2"></circle> 
            <circle cx="20" cy="9" r="2" stroke="#000000" stroke-width="2"></circle> 
            <path d="M4 14H3.69425C2.71658 14 1.8822 14.7068 1.72147 15.6712L1.38813 17.6712C1.18496 18.8903 2.12504 20 3.36092 20H7" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> 
            <path d="M20 14H20.3057C21.2834 14 22.1178 14.7068 22.2785 15.6712L22.6119 17.6712C22.815 18.8903 21.8751 20 20.6392 20C19.4775 20 18.0952 20 17 20" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> 
          </g>
        </svg>
      </span>
    </div>

    <div class="mt-1 flex gap-1 text-green-600">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
        />
      </svg>

      <p class="flex gap-2 text-xs">
        <span class="font-medium"> 67.81% </span>

        <span class="text-gray-500"> Since last week </span>
      </p>
    </div>
  </article>
</template>
<script>
export default {
  name: 'AdminNav',
  data: function () {
    return {
    }
  },
  props: ['stat']
}
</script>

<style scoped>
.stat-container {
  width: 30%;
  height: 15vh;
  padding: 5px;
  background: #EEEEEE;
  border-radius: 5px;
}
.title {
 color: rgb(0, 0, 0);
 font-weight: 600;
 font-size: 18px;
}
</style>
