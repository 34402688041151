<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="37.393" height="37.634" viewBox="0 0 37.393 37.634">
        <g id="Group_1420" data-name="Group 1420" transform="translate(0 37.634)">
        <path id="Path_11794" data-name="Path 11794" d="M26.071-31.122A17.057,17.057,0,0,0,10.7-28.848c-4.257,3.1-5.307,8.283-4.431,13.228C7.786-7.049,17.151-4.4,24.468-7.669c3.617-1.619,5.718-5.5,6.073-9.348.39-4.206-3.353-7.873-7.12-9.145-4.5-1.52-8.464.656-10.068,4.986s.867,7.9,5.507,8.155A4.654,4.654,0,0,0,23.608-16.5c.509-2.348-2.229-3.863-3.993-4.536-1.434-.547-2.049,1.752-.631,2.293a5.959,5.959,0,0,1,1.414.707q1.087,2.186-.7,2.535a4.18,4.18,0,0,1-3.1-.519c-1.957-1-1.579-3.09-.845-4.756,1.6-3.624,5.258-4.347,8.584-2.38a11.3,11.3,0,0,1,3.358,3.328c1.075,1.648.3,3.706-.26,5.378-.988,2.962-3.154,4.6-6.118,5.4a12.568,12.568,0,0,1-8.083-.6c-3.1-1.344-4.225-4.082-4.785-7.24C6.7-26.728,17.277-31.475,25.44-28.829,26.9-28.356,27.524-30.651,26.071-31.122Z" fill="#f6b45e"/>
        <path id="Path_11795" data-name="Path 11795" d="M18.919-33.594H23.56l-2.321-4.04Z" fill="#f6b45e"/>
        <path id="Path_11796" data-name="Path 11796" d="M11.851-31.632l4.407-1.451-3.469-3.112Z" fill="#f6b45e"/>
        <path id="Path_11797" data-name="Path 11797" d="M6.566-27.485,9.909-30.7,5.437-32.006Z" fill="#f6b45e"/>
        <path id="Path_11798" data-name="Path 11798" d="M3.8-21.343l1.415-4.419-4.556.977Z" fill="#f6b45e"/>
        <path id="Path_11799" data-name="Path 11799" d="M4.279-14.67l-.528-4.61L0-16.516Z" fill="#f6b45e"/>
        <path id="Path_11800" data-name="Path 11800" d="M7.763-8.328,5.22-12.209,3.112-8.054Z" fill="#f6b45e"/>
        <path id="Path_11801" data-name="Path 11801" d="M13.2-4.269,9.033-6.314l.3,4.65Z" fill="#f6b45e"/>
        <path id="Path_11802" data-name="Path 11802" d="M20.953-4.151l-4.634.231L18.838,0Z" fill="#f6b45e"/>
        <path id="Path_11803" data-name="Path 11803" d="M28.011-7.043l-4.048,2.27,4,2.389Z" fill="#f6b45e"/>
        <path id="Path_11804" data-name="Path 11804" d="M32.444-12.386,29.862-8.531l4.648.322Z" fill="#f6b45e"/>
        <path id="Path_11805" data-name="Path 11805" d="M33.517-19.9l-.311,4.628,4.187-2.044Z" fill="#f6b45e"/>
        <path id="Path_11806" data-name="Path 11806" d="M29.34-26.551l2.677,3.791,1.963-4.225Z" fill="#f6b45e"/>
        </g>
    </svg>
</template>
<script>
export default {
  name: 'SunDecoration'
}
</script>
<style>
</style>
