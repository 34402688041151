<template>
  <div class="pagecontainer bg-white">
    <div class="adminbody">
      <sidenav active="Bookings"></sidenav>
      <div class="flex flex-col bg-slate-100 overflow-scroll">
        <div class="page-title">Bookings</div>
        <div class="w-full p-1 flex rounded-sm gap-4">
          <span class="rounded bg-teal-600 text-white p-3 shadow-sm">Total Bookings: {{ bookings.length }}</span>
          <span class="rounded bg-yellow-600 text-white p-3 shadow-sm">Pending Payment: {{ bookings.length }}</span>
        </div>
        <div class="w-full flex flex-col justify-center items-center">
          <div class="w-4/5 shadow-lg">
            <div class="bg-slate-900 p-2 gap-2 grid grid-cols-6">
              <span class="text-white w-60 text-sm mx-1">Package</span>
              <span class="text-white w-60 text-sm mx-1">Name</span>
              <span class="text-white w-60 text-sm mx-1">Availability</span>
              <span class="text-white w-60 text-sm mx-1">Payment Status</span>
              <span class="text-white w-60 text-sm mx-1">Date</span>
              <span class="text-white w-60 text-sm mx-1">Actions</span>
            </div>
            <bookinglist :bookingitem=item v-for="item in bookings" :key="item.ref"></bookinglist>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import adminheader from '@/components/admin/header.vue'
import axios from 'axios'
import Sidenav from '@/components/sidenav.vue'
import bookinglist from '@/views/Admin/Bookings/list.vue'

export default {
  name: 'BookingsDashboard',
  components: {
    adminheader,
    Sidenav,
    bookinglist
  },
  data: function () {
    return {
      bookings: [],
      prepedbookings:[]
    }
  },
  methods: {
    getbookings: function () {
      axios(
        {
          method: 'post',
          url: process.env.VUE_APP_BASE_URL + '/api/get/bookings',
          headers: { Authorization: 'Bearer ' + this.$store.state.token }
        })
        .then((response) => {
          console.log(response)
          if (typeof this.users !== 'undefined' || this.users !== null) {
            this.bookings = response.data.bookings
            this.prepbookings()
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    prepbookings: function (filter) {
      let counter = 0
      this.prepbookings = []

      this.bookings.forEach((item) =>{
        item['count'] =  counter
        counter++
      })
    }
  },
  created () {
    this.getbookings()
  }
}
</script>
<style scoped>
.pagecontainer {
    display: flex;
    flex-direction: column;
  }

  .btn-open-package {
    background-color: #4FC3F7;
    color: #fff;
    font-weight: 700;
    padding: 10px 10px;
    border-radius: 5px;
  }
  .adminbody {
    display: grid;
    grid-template-columns: 250px 1fr;
  }

  .pagecontent {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .page-title {
    padding: 20px;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .page-list {
    width: 800px;
  }
</style>
