<template>
    <div class="flex flex-col p-2 w-full gap-2">
    <span class="text-lg text-gray-800">Send Proof of Payment Details</span>
    <div class="flex flex-row gap-4 p-2 items-center justify-center" v-if="show_preloader">
        <div class="bookingform-preloader" v-if="show_preloader">
                <svg xmlns="http://www.w3.org/2000/svg" width="37.393" height="37.634" viewBox="0 0 37.393 37.634" class="animate__animated animate__heartBeat animate__infinite">
                  <g id="Group_1424" data-name="Group 1424" transform="translate(0 37.634)">
                    <path id="Path_11794" data-name="Path 11794" d="M26.071-31.122A17.057,17.057,0,0,0,10.7-28.848c-4.257,3.1-5.307,8.283-4.431,13.228C7.786-7.049,17.151-4.4,24.468-7.669c3.617-1.619,5.718-5.5,6.073-9.348.39-4.206-3.353-7.873-7.12-9.145-4.5-1.52-8.464.656-10.068,4.986s.867,7.9,5.507,8.155A4.654,4.654,0,0,0,23.608-16.5c.509-2.348-2.229-3.863-3.993-4.536-1.434-.547-2.049,1.752-.631,2.293a5.959,5.959,0,0,1,1.414.707q1.087,2.186-.7,2.535a4.18,4.18,0,0,1-3.1-.519c-1.957-1-1.579-3.09-.845-4.756,1.6-3.624,5.258-4.347,8.584-2.38a11.3,11.3,0,0,1,3.358,3.328c1.075,1.648.3,3.706-.26,5.378-.988,2.962-3.154,4.6-6.118,5.4a12.568,12.568,0,0,1-8.083-.6c-3.1-1.344-4.225-4.082-4.785-7.24C6.7-26.728,17.277-31.475,25.44-28.829,26.9-28.356,27.524-30.651,26.071-31.122Z" fill="#f6b45e"/>
                    <path id="Path_11795" data-name="Path 11795" d="M18.919-33.594H23.56l-2.321-4.04Z" fill="#f6b45e"/>
                    <path id="Path_11796" data-name="Path 11796" d="M11.851-31.632l4.407-1.451-3.469-3.112Z" fill="#f6b45e"/>
                    <path id="Path_11797" data-name="Path 11797" d="M6.566-27.485,9.909-30.7,5.437-32.006Z" fill="#f6b45e"/>
                    <path id="Path_11798" data-name="Path 11798" d="M3.8-21.343l1.415-4.419-4.556.977Z" fill="#f6b45e"/>
                    <path id="Path_11799" data-name="Path 11799" d="M4.279-14.67l-.528-4.61L0-16.516Z" fill="#f6b45e"/>
                    <path id="Path_11800" data-name="Path 11800" d="M7.763-8.328,5.22-12.209,3.112-8.054Z" fill="#f6b45e"/>
                    <path id="Path_11801" data-name="Path 11801" d="M13.2-4.269,9.033-6.314l.3,4.65Z" fill="#f6b45e"/>
                    <path id="Path_11802" data-name="Path 11802" d="M20.953-4.151l-4.634.231L18.838,0Z" fill="#f6b45e"/>
                    <path id="Path_11803" data-name="Path 11803" d="M28.011-7.043l-4.048,2.27,4,2.389Z" fill="#f6b45e"/>
                    <path id="Path_11804" data-name="Path 11804" d="M32.444-12.386,29.862-8.531l4.648.322Z" fill="#f6b45e"/>
                    <path id="Path_11805" data-name="Path 11805" d="M33.517-19.9l-.311,4.628,4.187-2.044Z" fill="#f6b45e"/>
                    <path id="Path_11806" data-name="Path 11806" d="M29.34-26.551l2.677,3.791,1.963-4.225Z" fill="#f6b45e"/>
                  </g>
                </svg>
              </div>
    </div>
    <div class="flex flex-col" v-if="!show_preloader">


      <div class="flex flex-col md:flex-row">
        <RadioButton v-model="payment_type" class="text-lg" label="Deposit" value="deposit" :disabled="!hasIntlBookingPrice || !haslocalBookingPrice"/>
        <RadioButton v-model="payment_type" class="text-lg" label="Full Payment" value="full_payment"/>
      </div>
      <div class="py-1 px-2 text-sm text-afpinkred-normal">
        <p v-if="isPackageLocal && haslocalBookingPrice != null && payment_type == 'deposit'">You  have selected to pay <span class="font-bold">BWP {{ selectedbooking.package.pricing.local_booking }}</span>
        </p>
        <p v-if="isPackageLocal && hasLocalPrice != null && payment_type == 'full_payment'">You have selected to pay <span class="font-bold">BWP {{ selectedbooking.package.pricing.local_price}}</span>
        </p>
        <p v-if="isPackageIntl && hasIntlBookingPrice != null && payment_type == 'deposit'">You have selected to pay <span class="font-bold">USD {{ selectedbooking.package.pricing.intl_booking }}</span>
        </p>
        <p v-if="isPackageIntl && hasIntlPrice && payment_type == 'full_payment'">You have selected to pay <span class="font-bold">USD {{ selectedbooking.package.pricing.intl_price }}</span>
        </p>
      </div>
        <div class="flex flex-col">
            <span class="mt-2 text-xs text-gray-500">Proof of Payment</span>
            <input type="file" class="border border-gray-400 rounded-full w-full p-2  file:mr-4 file:py-2 file:px-4
      file:rounded-full file:border-0s
      file:text-sm file:font-semibold
      file:bg-afgreen-normal/60 file:text-white
      hover:file:bg-afgreen-normal" v-on:change="fileChanged" accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document">
            <span class="mt-2 text-xs text-gray-500">Allowed file type: PDF,DOC or DOCX</span>
        </div>
        <div class="flex flex-row justify-center">
          <span class="bg-aforange-normal shadow-sm rounded flex justify-center content-center text-white mt-2 cursor-pointer p-2" @click.prevent="applyPayment()">Send</span>
        </div>
    </div> 
  </div>
</template>
<script>
import axios from 'axios'
import backButtonComponent from '../backButtonComponent.vue'
import RadioButton from '../RadioButton.vue';
export default {
  name: 'BookingPayment',
  props: ['selectedbooking'],
  components: {
    backButtonComponent,
    RadioButton
},
  data: function () {
        return {
            baseUrl: process.env.VUE_APP_BASE_URL,
            token: this.$store.state.token,
            auth: this.$store.state.loggedin,
            mediaUrl: process.env.VUE_APP_BASE_URL + '/media/get/',
            selectedIndex: 0,
            loaded: false,
            paidFor: false,
            paymenttoken: {},
            pop:"",
            show_preloader: false,
            payment_type:"full_payment"
        }
  },
  methods: {
    fileChanged: function($e){
        console.log("gFIle changed");
        const target = $e.target;
        console.log("Target: "+JSON.stringify(target));
            if (target && target.files) 
            { 
                console.log("FIle changed setting pop");
                console.log("Files : "+JSON.stringify(target.files[0]));

                this.pop = target.files[0];
            }
            console.log("POP: "+this.pop);
    },
    setLoaded: function () {
      this.loaded = true
    },
    applyPayment: function () {
      const myFormData = new FormData()
      myFormData.set('booking', this.selectedbooking.ref)
     
      if(this.selectedbooking.package.availability == 'local'){
        myFormData.set('amount', this.selectedbooking.package.local_price)
      }
      myFormData.set('payment_ref', (this.selectedbooking.package.name.replace(/\s/g,"_") +"_"+this.selectedbooking.ref).toLowerCase())
      myFormData.set('address', this.selectedbooking.user.email)
      myFormData.set('payer', this.selectedbooking.user.name)
      myFormData.set('status', "paid")
      myFormData.set('pop',this.pop)

      this.show_preloader = true
      try {
        axios(
        {
          method: 'post',
          data: myFormData,
          url: this.baseUrl + '/api/payment/apply',
          headers: { Authorization: 'Bearer ' + this.$store.state.token }
        })
        .then((response) => {
          this.show_preloader = false
          console.log('payment response : ' + JSON.stringify(response.data.payments))
          this.afribooking = response.data.booking
          this.$emit('refreshPayments',response.data.payments);
        })
        .catch(function (error) {
          this.show_preloader = false
          console.log(error)
        })
    
      } catch (error) {
        this.show_preloader = false
        console.log(error)
      }
    }

  },
  computed:{

    hasIntlBookingPrice(){
      return this.selectedbooking != null && this.selectedbooking.package != null && this.selectedbooking.package.pricing.intl_booking != null
    },
    haslocalBookingPrice(){
      return this.selectedbooking != null && this.selectedbooking.package != null && this.selectedbooking.package.pricing.local_booking != null
    },

    hasLocalPrice(){
      return this.selectedbooking != null && this.selectedbooking.package != null && this.selectedbooking.package.pricing.local_price != null
    },
    hasIntlPrice(){
      return this.selectedbooking != null && this.selectedbooking.package != null && this.selectedbooking.package.pricing.intl_price != null
    },
    isPackageIntl(){
      return this.selectedbooking != null && this.selectedbooking.package != null && this.selectedbooking.package.availability == 'international';
    },
    isPackageLocal(){
      return this.selectedbooking != null && this.selectedbooking.package != null && this.selectedbooking.package.availability == 'local';
    }
  }

}
</script>