<template>
  <div class="pagecontainer bg-white">
    <div class="adminbody">
      <sidenav active="Media"></sidenav>
      <div class="pagecontent">
        <div class="page-title">Media</div>
        <div class="p-2 rounded shadow bg-slate-200 flex justify-center">
          <input v-if="!isbusy" type="file" @change="onFileChange"/>
          <span v-if="isbusy" class="loader"></span>
        </div>
        <div class="flex flex-wrap w-full overflow-auto p-4 mb-4 h-5/6 bg-slate-50 gap-4 justify-center">
          <medialist :imageitem=item v-for="item in images" :key="item.ref"></medialist>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import Sidenav from '@/components/sidenav.vue'
import medialist from '@/views/Admin/Media/list.vue'
import router from '@/router'

export default {
  name: 'MediaDashboard',
  components: {
    Sidenav,
    medialist
  },
  data: function () {
    return {
      images: [],
      baseUrl: process.env.VUE_APP_BASE_URL,
      url: process.env.VUE_APP_BASE_URL + '/api/media/create',
      headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + this.$store.state.token },
      filesUploaded: [],
      selectedFile: {},
      isbusy: false
    }
  },
  methods: {
    getMedia: function () {
      axios(
        {
          method: 'post',
          url: process.env.VUE_APP_BASE_URL + '/api/admin/media',
          headers: { Authorization: 'Bearer ' + this.$store.state.token }
        })
        .then((response) => {
          console.log(response)
          this.images = response.data.media
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    openNewBlog: function () {
      router.push('/admin/blog/new')
    },
    onFileChange (event) {
      this.selectedFile = event.target.files[0]
      this.uploadFile()
    },
    onSuccess () {
      this.getMedia()
    },
    uploadFile () {
      this.isbusy = true
      const myFormData = new FormData()
      myFormData.set('name', this.selectedFile.name)
      myFormData.append('image', this.selectedFile, this.selectedFile.name)
      axios(
        {
          method: 'post',
          url: this.baseUrl + '/api/media/create',
          data: myFormData,
          headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + this.token }
        })
        .then((response) => {
          console.log(response)
          this.isbusy = false
          this.getMedia()
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  created () {
    this.getMedia()
  }
}
</script>
<style scoped>
.pagecontainer {
    display: flex;
    flex-direction: column;
  }

  .btn-open-package {
    background-color: #4FC3F7;
    color: #fff;
    font-weight: 700;
    padding: 10px 10px;
    border-radius: 5px;
  }
  .adminbody {
    display: grid;
    grid-template-columns: 250px 1fr;
  }

  .pagecontent {
    padding: 10px;
    display: flex;
    max-height: 80vh;
    overflow: auto;
    flex-direction: column;
  }

  .page-title {
    padding: 20px;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .page-list {
    width: 800px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .newbutton {
    background: #00ACC1;
    color: #fff;
    font-weight: 600;
    width: 150px;
    padding: 10px;
    border-radius: 5px;
  }

  .loader {
      width: 64px;
      height: 64px;
      position: relative;
      background: #FFF;
      border-radius: 4px;
      overflow: hidden;
    }
    .loader:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 40px;
      height: 40px;
      transform: rotate(45deg) translate(30%, 40%);
      background: #ff9371;
      box-shadow: 32px -34px 0 5px #ff3d00;
      animation: slide 2s infinite ease-in-out alternate;
    }
    .loader:after {
      content: "";
      position: absolute;
      left: 10px;
      top: 10px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #ff3d00;
      transform: rotate(0deg);
      transform-origin: 35px 145px;
      animation: rotate 2s infinite ease-in-out;
    }

    @keyframes slide {
      0% , 100%{ bottom: -35px}
      25% , 75%{ bottom: -2px}
      20% , 80%{ bottom: 2px}
    }
    @keyframes rotate {
      0% { transform: rotate(-15deg)}
      25% , 75%{ transform: rotate(0deg)}
      100% {  transform: rotate(25deg)}
    }
</style>
