<template>
  <div class="app header-default side-nav-dark">
    <div class="layout">
      <adminheader></adminheader>
      <Sidenav></Sidenav>
      <!-- Page Container START -->
      <div class="page-container">
          <!-- Content Wrapper START -->
          <div class="main-content">
            <div class="container-fluid">
              <breadcrumb title="New Package"></breadcrumb>
              <div class="col-12 col-xl-10 m-b-10 offset-xl-1 offset-m-b-1">
                <!--Header-->
                <div class="card">
                  <div class="card-header border-bottom">
                    <h4 class="card-title">Header Image</h4>
                  </div>
                  <div class="card-body">
                    <div class="headerimage" v-if="afripackage.header_img != null">
                      <img :src="baseUrl + '/media/get/' + afripackage.header_img.ref" alt="">
                    </div>
                    <div class="gallery" v-if="showGallery">
                      <div class="image-container" v-on:click=" attachmedia(item.ref)"  v-for="item in mediafiles" :key="item.ref">
                        <img :src="baseUrl + '/media/get/' + item.ref" alt="">
                      </div>
                      <div class="image-container" v-on:click="toggleShowImageForm">
                        <i class="lni lni-add-files" style="font-size: 60px"></i>
                      </div>
                    </div>
                    <button type="button" class="next-btn" v-on:click="toggleShowGallery">Edit</button>
                  </div>
                </div>
                <!-- Header end -->
                 <!--Header-->
                <div class="card">
                  <div class="card-header border-bottom">
                    <h4 class="card-title">Gallery</h4>
                  </div>
                  <div class="card-body">
                    <div class="headerimage" v-if="afripackage.header_img != null">
                      <img :src="baseUrl + '/media/get/' + afripackage.header_img.ref" alt="">
                    </div>
                    <div class="gallery" v-if="showGallery">
                      <div class="image-container" v-on:click=" attachmedia(item.ref)"  v-for="item in mediafiles" :key="item.ref">
                        <img :src="baseUrl + '/media/get/' + item.ref" alt="">
                      </div>
                      <div class="image-container" v-on:click="toggleShowImageForm">
                        <i class="lni lni-add-files" style="font-size: 60px"></i>
                      </div>
                    </div>
                    <button type="button" class="next-btn" v-on:click="toggleShowGallery">Edit</button>
                  </div>
                </div>
                <!-- Header end -->
                <!--Details-->
                <div class="card">
                  <div class="card-header border-bottom">
                    <h4 class="card-title">Details</h4>
                  </div>
                  <div class="card-body">
                    <p>Name: {{afripackage.name}}</p>
                    <p>Description: {{afripackage.description}}</p>
                    <p>Price: {{afripackage.price}}</p>
                    <button type="button" class="next-btn">Edit</button>
                  </div>
                </div>
                <!-- Details end -->
                <!--Details-->
                <div class="card">
                  <div class="card-header border-bottom">
                    <h4 class="card-title">Itenary</h4>
                  </div>
                  <div class="card-body">
                    <div class="itenarycontainer" v-for="item in afripackage.itenary" :key="item.ref">
                      <h3>{{item.title}}</h3>
                      <p>{{item.description}}</p>
                    </div>
                    <div class="new-itenary-form" v-if="showItenaryForm">
                      <div class="form-group col-md-6">
                        <label for="exampleInputName1">Title</label>
                        <input type="text" class="form-control" id="exampleInputName1" placeholder="Name" v-model="itenary.title">
                      </div>
                      <div class="form-group col-md-6">
                        <label for="exampleInputName1">Type</label>
                        <input type="text" class="form-control" id="exampleInputName1" placeholder="Type" v-model="itenary.type">
                      </div>
                      <div class="form-group m-b-20 col-md-12">
                        <label for="exampleTextarea1">Description</label>
                        <textarea class="form-control" id="exampleTextarea1" rows="4" v-model="itenary.description"></textarea>
                      </div>
                    </div>
                    <button type="button" class="next-btn" v-if="!showItenaryForm" v-on:click="toggleShowItenaryForm()">Edit</button>
                    <button type="button" class="next-btn" v-if="showItenaryForm" v-on:click="createItenary()">Add</button>
                  </div>
                </div>
                <!-- Details end -->
              </div>
            </div>
          </div>
          <!-- Content Wrapper END -->
        <adminfooter></adminfooter>
      </div>
      <!-- Page Container END -->
    </div>
  </div>
</template>
<script>
import adminheader from '../../components/admin/header.vue'
import Sidenav from '../../components/sidenav.vue'
import adminfooter from '../../components/adminfooter.vue'
import breadcrumb from '../../components/breadcrumb.vue'
import axios from 'axios'
import router from '../../router'

export default {
  name: 'ViewPackage',
  components: {
    adminheader,
    Sidenav,
    adminfooter,
    breadcrumb
  },
  data: function () {
    return {
      afripackage: {
        name: '',
        price: 0,
        description: ''
      },
      mediafiles: [],
      name: '',
      description: '',
      price: 0,
      baseUrl: process.env.VUE_APP_BASE_URL,
      newPackageForm: {
        showForm: true,
        step: 1
      },
      showImageForm: false,
      selectedImage: '',
      showGallery: false,
      showItenaryForm: false,
      itenary: {
        title: '',
        description: '',
        type: ''
      }
    }
  },
  props: [
    'packageref'
  ],
  methods: {
    getpackage: function () {
      axios(
        {
          method: 'post',
          url: process.env.VUE_APP_BASE_URL + '/api/get/package/' + this.$route.params.packageref
        })
        .then((response) => {
          console.log(response.data.package)
          this.afripackage = response.data.package
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getmedia: function () {
      axios(
        {
          method: 'post',
          url: this.baseUrl + '/api/media'
        })
        .then((response) => {
          console.log(response)
          this.mediafiles = response.data.media
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    attachmedia: function (media) {
      const myFormData = new FormData()
      myFormData.set('package', this.afripackage.ref)
      myFormData.set('media', media)
      axios(
        {
          method: 'post',
          url: this.baseUrl + '/api/package/attach/media',
          data: myFormData,
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then((response) => {
          console.log(response)
          this.afripackage = response.data.package
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    toggleShowImageForm: function () {
      this.showImageForm = !this.showImageForm
    },
    toggleShowGallery: function () {
      this.showGallery = !this.showGallery
    },
    toggleShowItenaryForm: function () {
      this.showItenaryForm = !this.showItenaryForm
    },
    nextPage: function () {
      if (this.newPackageForm.step < 3) {
        this.newPackageForm.step += 1
      }
    },
    prevPage: function () {
      if (this.newPackageForm.step > 1) {
        this.newPackageForm.step -= 1
      }
    },
    createPackage: function () {
      const myFormData = new FormData()
      myFormData.set('name', this.name)
      myFormData.set('price', this.price)
      myFormData.set('description', this.description)

      axios({
        method: 'post',
        url: this.baseUrl + '/api/package/create',
        data: myFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then((response) => {
          console.log(response)
          router.push('/admin/packages')
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    createItenary: function () {
      const myFormData = new FormData()
      myFormData.set('title', this.itenary.title)
      myFormData.set('description', this.itenary.description)
      myFormData.set('package', this.afripackage.ref)
      myFormData.set('type', this.itenary.type)

      axios({
        method: 'post',
        url: this.baseUrl + '/api/itenary/add',
        data: myFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then((response) => {
          console.log(response)
          this.showItenaryForm = false
          this.afripackage = response.package
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  created () {
    this.getpackage()
    this.getmedia()
  }
}
</script>
<style scoped>
  .image-container {
    padding: 10px;
    width: 100px;
    height: 100px;
    margin-top: 10px;
    background-color: #fff;
    border-radius: 2px;
    -webkit-box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.32);
    box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.32);
  }

  .image-container .selected {
    background-color: #81D4FA;
  }

  .image-container img {
    width: 80px;
    height: 80px;
    object-fit: cover;
  }

  .image-container img {
    width: 80px;
    height: 80px;
    object-fit: cover;
  }

  .gallery {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 10px;
  }
  .card-body {
    text-align: left;
  }
  .back-btn {
    background-color: #FBC02D;
    font-weight: 800;
    color: #fff;
    border: none;
    border-radius: 5px;
    width: 100px;
    height: 30px;
  }

  .next-btn {
    background-color: #039BE5;
    font-weight: 800;
    color: #fff;
    border: none;
    border-radius: 5px;
    width: 100px;
    height: 30px;
    margin-left: 10px;
  }

  .headerimage img {
    width: 80%;
    height: 30vh;
    object-fit: cover;
  }
  .headerimage {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .itenarycontainer {
    display: flex;
    flex-direction: column;
  }
</style>
