<template>
    <div class="bg-[url('../assets/images/bg.png')] bg-cover backdrop-grayscale bg-white/30 h-full">
      <div class="loginform-container">
        <ErrorComponent :error="otp.error" v-if="showError" @closeError="closeErrorMessage()"/>
        <div class="flex flex-col bg-white p-4 rounded shadow gap-4">
          <h3 class="login-title">Sign In</h3>
                   
          <div v-if="otp.sent && !otp.recieved">
            <div class="alert alert-warning" role="alert">
              <!-- {{otp.error.message}} -->
            </div>
          </div>
          <div class="flex flex-col gap-4 justify-center content-center w-72" v-if="!otp.recieved">
            <div class="mb-2 flex flex-row">
              <input class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-yellow-600 focus:border-yellow-600 p-2.5 mb-2 w-full" type="email" required placeholder="Email" v-model="email">
            </div>
            <div v-if="show_button" class="text-center">
              <span  class="btn" v-on:click="requestOTP">Request OTP</span>
            </div>
            <div v-if="!app_busy" class="flex justify-center text-center mt-5">
              <div class="bookingform-preloader" v-if="show_preloader">
                <svg xmlns="http://www.w3.org/2000/svg" width="37.393" height="37.634" viewBox="0 0 37.393 37.634" class="animate__animated animate__heartBeat animate__infinite">
                  <g id="Group_1424" data-name="Group 1424" transform="translate(0 37.634)">
                    <path id="Path_11794" data-name="Path 11794" d="M26.071-31.122A17.057,17.057,0,0,0,10.7-28.848c-4.257,3.1-5.307,8.283-4.431,13.228C7.786-7.049,17.151-4.4,24.468-7.669c3.617-1.619,5.718-5.5,6.073-9.348.39-4.206-3.353-7.873-7.12-9.145-4.5-1.52-8.464.656-10.068,4.986s.867,7.9,5.507,8.155A4.654,4.654,0,0,0,23.608-16.5c.509-2.348-2.229-3.863-3.993-4.536-1.434-.547-2.049,1.752-.631,2.293a5.959,5.959,0,0,1,1.414.707q1.087,2.186-.7,2.535a4.18,4.18,0,0,1-3.1-.519c-1.957-1-1.579-3.09-.845-4.756,1.6-3.624,5.258-4.347,8.584-2.38a11.3,11.3,0,0,1,3.358,3.328c1.075,1.648.3,3.706-.26,5.378-.988,2.962-3.154,4.6-6.118,5.4a12.568,12.568,0,0,1-8.083-.6c-3.1-1.344-4.225-4.082-4.785-7.24C6.7-26.728,17.277-31.475,25.44-28.829,26.9-28.356,27.524-30.651,26.071-31.122Z" fill="#f6b45e"/>
                    <path id="Path_11795" data-name="Path 11795" d="M18.919-33.594H23.56l-2.321-4.04Z" fill="#f6b45e"/>
                    <path id="Path_11796" data-name="Path 11796" d="M11.851-31.632l4.407-1.451-3.469-3.112Z" fill="#f6b45e"/>
                    <path id="Path_11797" data-name="Path 11797" d="M6.566-27.485,9.909-30.7,5.437-32.006Z" fill="#f6b45e"/>
                    <path id="Path_11798" data-name="Path 11798" d="M3.8-21.343l1.415-4.419-4.556.977Z" fill="#f6b45e"/>
                    <path id="Path_11799" data-name="Path 11799" d="M4.279-14.67l-.528-4.61L0-16.516Z" fill="#f6b45e"/>
                    <path id="Path_11800" data-name="Path 11800" d="M7.763-8.328,5.22-12.209,3.112-8.054Z" fill="#f6b45e"/>
                    <path id="Path_11801" data-name="Path 11801" d="M13.2-4.269,9.033-6.314l.3,4.65Z" fill="#f6b45e"/>
                    <path id="Path_11802" data-name="Path 11802" d="M20.953-4.151l-4.634.231L18.838,0Z" fill="#f6b45e"/>
                    <path id="Path_11803" data-name="Path 11803" d="M28.011-7.043l-4.048,2.27,4,2.389Z" fill="#f6b45e"/>
                    <path id="Path_11804" data-name="Path 11804" d="M32.444-12.386,29.862-8.531l4.648.322Z" fill="#f6b45e"/>
                    <path id="Path_11805" data-name="Path 11805" d="M33.517-19.9l-.311,4.628,4.187-2.044Z" fill="#f6b45e"/>
                    <path id="Path_11806" data-name="Path 11806" d="M29.34-26.551l2.677,3.791,1.963-4.225Z" fill="#f6b45e"/>
                  </g>
                </svg>
              </div>
            </div>
          </div>
          <div v-if="otp.sent && otp.recieved">
            <div class="submitted-data">
              <span class="data-label">Email : </span>
              <span class="sub-data">{{email}}</span>
            </div>
            <div class="mb-2">
              <input class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-yellow-600 focus:border-yellow-600 block w-full p-2.5" type="text" required placeholder="OTP" v-model="otp.value">
            </div>
            <div v-if="!app_busy" class="text-center mt-5">
              <span  class="shadow-md px-5 py-2.5 rounded text-white bg-aforange-normal" v-on:click="validateOTP">Verify</span>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import axios from 'axios'
import router from '@/router'
import ErrorComponent from '../../components/public/ErrorComponent.vue'

export default {
  name: 'CustomerLogin',
  components: {
    ErrorComponent
},
  data: function () {
    return {
      password: '',
      otp_sent: false,
      otp_req_haserror: false,
      app_busy: false,
      email: '',
      user: {},
      showError:false,
      otp: {
        value: '',
        sent: false,
        recieved: false,
        inprogress: false,
        haserror: false,
        error: {
          message: '',
          code: ''
        }
      },
      show_button: true,
      show_preloader: false
    }
  },
  methods: {
    authenticate: function () {
      const myFormData = new FormData()
      myFormData.set('email', this.email)
      myFormData.set('password', this.password)

      axios({
        method: 'post',
        url: process.env.VUE_APP_BASE_URL + '/api/login',
        data: myFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then((response) => {
          this.$store.commit('setToken', response.data._token)
          this.$store.commit('setLoggedInUser', response.data.user)
          this.user = response.data.user
          if (this.user.role.alias === 'admin') {
            router.push('/admin/dashboard')
          } else {
            router.push('/member/dashboard')
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    requestOTP: function () {
      if(this.email)
      {
        this.show_button = false
      this.show_preloader = true
      const myFormData = new FormData()
        //console.log('authenticating' + this.email + 'requesting OTP')
      myFormData.set('email', this.email)
      axios({
        method: 'post',
        url: process.env.VUE_APP_BASE_URL + '/api/email/login/resend',
        data: myFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then((response) => {
          this.otp.sent = true
          if (response.data.success === false) {
            this.otp.recieved = false
            this.otp.error.message = response.data.message
            this.otp.error.code = response.data.error_code
            this.showError = true
          } else {
            this.otp.recieved = true
          }
          this.show_button = true
          this.show_preloader = false
        })
        .catch(function (error) {
          console.log(error)
          this.otp.error.message = error
          this.showError = true
          this.show_button = false
          this.show_preloader = true
        })
      }
      else {
        this.otp.error.message = "Please provide an email"
        this.showError = true
      }
      
    },
    validateOTP: function () {
      const myFormData = new FormData()
      //console.log('authenticating' + this.email + 'with pass ' + this.password)
      myFormData.set('token', this.otp.value)
      myFormData.set('email', this.email)
      axios({
        method: 'post',
        url: process.env.VUE_APP_BASE_URL + '/api/email/login/confirm',
        data: myFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then((response) => {
          //console.log(response)
          if (response.data.success == true) {
            this.$store.dispatch('setTokenAction', response.data._token)
            this.$store.commit('setLoggedInUser', response.data.user)
            this.user = response.data.user
            if (this.user.isAdmin) {
              router.push('/admin/dashboard')
            } else {
              router.push('/member/dashboard')
            }
          }
          else{
            this.otp.error.message = response.data.message
            this.showError = true
           
          }
         
        })
        .catch(function (error) {
          console.log(error)
          this.otp.error.message = error
            this.showError = true
        })
    }
    ,closeErrorMessage:function(){
       this.otp.error.message = ''
       this.showError = false;
    }
  }
}
</script>
<style scoped>
.container {
  margin-top: 50px;
}

.loginform-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 85vh;
}


.login-title {
  font-weight: 600;
  color: #00838F;
}

.submitted-data {
  display : flex;
  padding: 0.5rem;
  background: #EEEEEE;
  margin-bottom: 1rem;
  border-radius: 2px;
}

.data-label {
  color:#00838F
}
</style>
