
<template>
<div id="default-carousel" class="relative" data-carousel="static">
    <!-- Carousel wrapper -->
    <div class="relative overflow-hidden rounded-lg ">
         <!-- Item 1 -->
        <div  v-for="(item, index) of items" :key="index"  :class="current_active == index ? 'flex' : 'hidden'" data-carousel-item>

            <img v-lazy="{ src:mediaUrl +  item.ref,loading: '/images/loader.gif', error: '/images/loader.gif' }" class="object-scale-down w-full h-96 bg-slate-200" alt="...">
        </div>
        </div>
    
    <!-- Slider indicators -->
    <div class="absolute z-30 flex space-x-3 -translate-x-1/2 bottom-5 left-1/2">
        <button v-for="(item, index) of items" type="button" class="w-3 h-3 rounded-full" aria-current="false" :aria-label="item" :data-carousel-slide-to="index"
        ></button>
    </div>
    <!-- Slider controls -->
    <button type="button" class="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev @click.prevent="previous()" v-if="hasPrevious">
        <span class="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 group-focus:ring-4 group-focus:ring-white group-focus:outline-none">
            <svg aria-hidden="true" class="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
            <span class="sr-only">Previous</span>
        </span>
    </button>
    <button type="button" class="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next @click.prevent="next()" v-if="hasNext">
        <span class="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 group-hover:bg-white/50  group-focus:ring-4 group-focus:ring-white group-focus:outline-none">
            <svg aria-hidden="true" class="w-5 h-5 text-white sm:w-6 sm:h-6 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
            <span class="sr-only">Next</span>
        </span>
    </button>
</div>

</template>
<script>
export default {
  name: 'ImageCarousel',
  props:['items'],
  data: function () {
    return {
        baseUrl: process.env.VUE_APP_BASE_URL,
        mediaUrl:process.env.VUE_APP_BASE_URL+'/media/get/',
        current_active: 0
    }
  },
  methods:
  {
    next: function () {
            if (this.current_active < this.items.length-1) {
                this.current_active++
            }

        },
    previous: function () {
        if (this.current_active > 0) {
            this.current_active--
        }
    }
},
computed:{
    hasNext:function(){
        return this.current_active != this.items.length -1
    },
    hasPrevious:function(){
        return this.current_active > 0
    }
}

}

</script>
<style>

.modal-enter-active {
    animation: bounce-in .5s ease-out both;
}

.modal-leave-active {
    animation: bounce-in .5s reverse ease-in both;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.10);
    }
    100% {
        transform: scale(1);
    }
}
</style>