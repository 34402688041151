<template>
  <div class="pagecontainer bg-white">
    <adminheader></adminheader>
    <div class="adminbody">
      <sidenav active="Blogs"></sidenav>
      <div class="pagecontent">
        <div class="page-title">New Blog</div>
        <div class="page-list">
          <div class="selectimage">
            <span class="afrilabel">Header Image</span>
            <div class="afrigalleryselectedheader" v-if="blog.headerimage">
              <img class="galleryheaderitem" :src="baseUrl + '/media/get/' + blog.headerimage">
            </div>
            <div class="afrigallery">
              <img @click="selectImage(item.ref)" class="afrigalleryitem" :src="baseUrl + '/media/get/' + item.ref" v-for="item in media" :key="item.ref" alt="">
            </div>
          </div>
          <div class="form-input-afri">
            <span class="afrilabel">Title</span>
            <input class="border ml-2 focus:bg-slate-100 border-l-teal-500 rounded" type="text" v-model="blog.name">
          </div>
          <div class="form-input-afri">
            <span class="afrilabel">Content</span>
            <textarea class="border ml-2 focus:bg-slate-100 border-l-teal-500 rounded" type="text" v-model="blog.content"></textarea>
          </div>
          <div class="  border-t pt-4 m-2">
            <span class="bg-emerald-400 p-2 text-white rounded shadow-sm hover:scale-110 duration-500 font-bold cursor-pointer" @click="saveBlog()">Save Changes</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import adminheader from '@/components/admin/header.vue'
import axios from 'axios'
import Sidenav from '@/components/sidenav.vue'
import router from '@/router'

export default {
  name: 'NewBlog',
  components: {
    adminheader,
    Sidenav
  },
  data: function () {
    return {
      blog: {
        name: '',
        content: '',
        headerimage: ''
      },
      media: [],
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  methods: {
    saveBlog: function () {
      const myFormData = new FormData()
      myFormData.set('title', this.blog.name)
      myFormData.set('content', this.blog.content)
      myFormData.set('media', this.blog.headerimage)
      axios(
        {
          method: 'post',
          data: myFormData,
          url: process.env.VUE_APP_BASE_URL + '/api/admin/blog/create',
          headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + this.$store.state.token }
        })
        .then((response) => {
          console.log(response)
          router.push('/admin/blog')
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    isSelectedItem (itemref) {
      return this.blog.headerimage === itemref
    },
    getMedia: function () {
      axios(
        {
          method: 'post',
          url: process.env.VUE_APP_BASE_URL + '/api/media',
          headers: { Authorization: 'Bearer ' + this.$store.state.token }
        })
        .then((response) => {
          console.log(response)
          this.media = response.data.media
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    selectImage (imageref) {
      this.blog.headerimage = imageref
      console.log('Selected image ' + imageref)
    }
  },
  created () {
    this.getMedia()
  }
}
</script>
<style scoped>
.pagecontainer {
    display: flex;
    flex-direction: column;
  }

  .btn-open-package {
    background-color: #4FC3F7;
    color: #fff;
    font-weight: 700;
    padding: 10px 10px;
    border-radius: 5px;
  }
  .adminbody {
    display: grid;
    grid-template-columns: 250px 1fr;
  }

  .pagecontent {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }

  .page-title {
    padding: 20px;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .page-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .form-input-afri {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

  .afrilabel {
    font-size: 12px;
    font-weight: 600px;
    color: #000;
  }

   .toolbox {
    width: 100%;
    height: 10vh;
    padding: 2vh;
    display: grid;
    grid-template-columns: 200px 1fr;
  }

  .newbutton {
    background: #00BFA5;
    color: #fff;
    font-weight: 600;
    width: 150px;
    padding: 10px;
    border-radius: 5px;
  }

  .afrigallery {
    height: 20vh;
    width: 100%;
    display: flex;
    gap: 10px;
    margin-top: 20px;
    flex-wrap: wrap;
    overflow: scroll;
  }

  .afrigalleryitem {
    height: 17vh;
    width: 200px;
    object-fit: cover;
  }

  .afrigalleryitemselected {
    height: 17vh;
    width: 200px;
    object-fit: cover;
    border: 3px solid #00E5FF;
  }

  .afrigalleryselectedheader {
    background: #9E9E9E;
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .galleryheaderitem {
    height: 100%;
    width: 800px;
    object-fit:cover;
  }
</style>
