<template>
  <div class="grid grid-cols-6 p-2 bg-white gap-2 border-b border-black">
    <div class="text-xs mx-1">{{ bookingitem.package.name }}</div>
    <div class="text-xs mx-1">{{ bookingitem.user.name }}</div>
    <div class="text-xs mx-1">{{ bookingitem.package.availability }}</div>
    <div class="text-xs mx-1">{{ fn_ispaid() }}</div>
    <div class="text-xs mx-1">{{ bookingitem.created_at }}</div>
    <div class="text-xs mx-1"><span class="p-1 bg-blue-400 text-white rounded cursor-pointer" @click="openBooking(bookingitem.ref)">Open</span></div>
  </div>
</template>

<script>
export default {
  name: 'BookingsList',
  props: ['bookingitem'],
  data: function () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  methods: {
    fn_ispaid: function () {
      let paid = 'pending payment'
      if(this.bookingitem.payments.length > 0)
      {
        paid = 'pending payment approval'
      }
      return paid
    },
    fn_openBooking: function (booking_ref) {

    }
  }
}
</script>

<style>
.package-row {
  display: grid;
  grid-template-columns: 50px 150px 1fr;
  background: #ECEFF1;
  width: 100%;
  padding: 10px;
  height: 70px;
  border-radius: 5px;
  gap: 10px;
  box-shadow: 1px 2px 1px -2px rgba(0,0,0,0.48);
  -webkit-box-shadow: 1px 2px 1px -2px rgba(0,0,0,0.48);
  -moz-box-shadow: 1px 1px 2px -2px rgba(0,0,0,0.48);
}

.packageimage {
  height: 50px;
  width: 50px;
  object-fit: cover;
}

.packagename {
  font-weight: 600;
  color: #2196F3;
}

</style>
