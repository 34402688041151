<template>
  <div class="grid grid-cols-3 grid-flow-row h-20 p-5 rounded shadow bg-slate-300 gap-2 my-3" @click="editblog(blogitem)">
    <img class="packageimage" v-if="blogitem.headerimg" :src="baseUrl + '/media/get/' + blogitem.headerimg.ref" alt="">
    <div class="packagename">{{ blogitem.title }}</div>
    <div class="packagedesc">{{ blogitem.created_at }}</div>
  </div>
</template>

<script>
import router from '@/router'
export default {
  name: 'BlogList',
  props: ['blogitem'],
  data: function () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  methods: {
    editblog: function (blog) {
      this.$store.commit('setSelectedBlog', blog)
      router.push('/admin/edit/blog')
    }
  }
}
</script>

<style>
.package-row {
  display: grid;
  grid-template-columns: 50px 150px 1fr;
  background: #ECEFF1;
  width: 100%;
  padding: 10px;
  height: 70px;
  border-radius: 5px;
  gap: 10px;
  box-shadow: 1px 2px 1px -2px rgba(0,0,0,0.48);
  -webkit-box-shadow: 1px 2px 1px -2px rgba(0,0,0,0.48);
  -moz-box-shadow: 1px 1px 2px -2px rgba(0,0,0,0.48);
  margin-bottom: 10px;
}

.packageimage {
  height: 50px;
  width: 50px;
  object-fit: cover;
}

.packagename {
  font-weight: 600;
  color: #2196F3;
}

.packagedesc {
  color: #000;
  font-size: 0.9rem;
}

</style>
